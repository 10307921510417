import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
const AVAILABLESLOCALES = ["uz_latn", "ru", "uz_cyrl"];
const locLocale = localStorage.getItem("locale");

const locale = AVAILABLESLOCALES.includes(locLocale)
  ? locLocale
  : AVAILABLESLOCALES[0];

const messages = {
  uz_latn: {},
  uz_cyrl: {},
  ru: {},
};

const i18n = new VueI18n({
  locale,
  messages,
});

try {
  const messages = await import(`./locales/${locale}.json`);
  i18n.setLocaleMessage(locale, { ...messages });
} catch (e) {
  console.error(e);
}

export default i18n;
