export default {
  data() {
    return {
      globalFilter: {
        statusIds: [],
        fromDocDate: "",
        toDocDate: "",
        eduYearId: null,
        currentEduYear: true,
        search: null,
        sortBy: null,
        orderType: null,
      },
    };
  },
  methods: {
    getColor(item) {
      if (
        item.statusId == 24 ||
        item.statusId == 21 ||
        item.statusId == 15 ||
        item.statusId == 10 ||
        item.statusId == 8
      ) {
        return "status-name-danger";
      }
      if (item.statusId == 12) {
        return "status-name-warning";
      } else {
        return "status-name-success";
      }
    },
    makeToast(error, type) {
      let a = "";
      if (error?.status == 500) {
        a = this.$t(
          "Ma'lumot topilmadi. OTM mas'ullariga murojaat qiling.",
          "error"
        );
      } else if (error?.status == 400) {
        let errors = Object.values(error.errors);
        a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = error;
      }

      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
  },
};
